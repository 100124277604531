import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { HealthMeasurementDetailMetadataItem } from "../HealthMeasurementDetail";
import ButtonWithAnalytics from "../ButtonWithAnalytics";

interface HealthMeasurementDetailUpdateProps {
  healthMeasurementIdentifier: string;
  healthMeasurementMetadata: HealthMeasurementDetailMetadataItem;
  isUserEditable: boolean;
}

export default function HealthMeasurementDetailUpdate({
  healthMeasurementIdentifier,
  isUserEditable,
  healthMeasurementMetadata: healthMeasurementItem,
}: HealthMeasurementDetailUpdateProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function onClick() {
    navigate(`/home/health-measurement/${healthMeasurementIdentifier}`);
  }

  const { isValueVisible, isUpdateButtonVisible, updateViewIcon } =
    healthMeasurementItem.updateComponentState || {};

  return (
    <Stack
      component="article"
      aria-label={`${t(
        `HealthProfile.${healthMeasurementIdentifier}.detail.update.label` as any
      )}`}
      alignItems="center"
      spacing={2}
      width="100%"
    >
      {isValueVisible === true && (
        <Typography variant="h4">
          {t(
            `HealthProfile.${healthMeasurementIdentifier}.detail.update.title` as any
          )}
        </Typography>
      )}

      <Stack direction="row" spacing={2} alignItems="center">
        {isValueVisible === true && (
          <>
            <Stack
              justifyContent="center"
              alignItems="center"
              justifySelf="center"
              bgcolor="accent2.light"
              sx={{
                borderRadius: 2,
                width: 51,
                height: 51,
              }}
            >
              {updateViewIcon}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="flex-end">
              <Typography variant="h1">
                {healthMeasurementItem?.valueAsJSON?.value}
              </Typography>
              <Typography>
                {healthMeasurementItem?.valueAsJSON?.unit?.toLowerCase()}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>

      {isUpdateButtonVisible === true && (
        <>
          <ButtonWithAnalytics
            page="HealthMeasurementDetailUpdate"
            text={t(
              `HealthProfile.${healthMeasurementIdentifier}.update.button.title` as any
            )}
            intent="navigational"
            onClick={onClick}
            disabled={!isUserEditable}
            fullWidth
          >
            {t(
              `HealthProfile.${healthMeasurementIdentifier}.update.button.title` as any
            )}
          </ButtonWithAnalytics>
        </>
      )}
    </Stack>
  );
}
